import React, { useRef, useEffect } from "react";
import cn from "classnames";
import SearchResultLoader from "../ui/loaders/search-result-loader";
import { useUI } from "@/contexts/ui.context";
import SearchBox from "./search-box";
import { SEARCH_PRODUCTS } from "@/framework/queries/search";
import SearchProduct from "./search-product";
import { useQuery } from "urql";
import { sanitize } from "@/framework/utils/sanitize";
import { useScrollBlock } from "@/utils/scroll-block";
import useOnClickOutside from "@/utils/use-click-outside";

export default function Search() {
  const { displaySearch, closeSearch, showToast } = useUI();
  const ref = useRef(null);
  const searchContainerRef = useRef(null);
  const [searchText, setSearchText] = React.useState();
  const [blockScroll, allowScroll] = useScrollBlock();
  useEffect(() => {
    if (displaySearch) {
      blockScroll();
    } else {
      allowScroll();
    }
  }, [displaySearch, blockScroll, allowScroll]);
  useEffect(() => {
    setSearchText("platter");
  }, [displaySearch]);
  useOnClickOutside(searchContainerRef, () => closeSearch());

  const [{ data, fetching, error }] = useQuery({
    query: SEARCH_PRODUCTS,
    variables: { search: searchText, limit: 30 },
    pause: !searchText || searchText.length < 3,
  });
  //   useScrollLock(displaySearch);

  function handleSearch(data) {
    setSearchText(data.search);
  }
  function handleAutoSearch(e) {
    e.preventDefault();
    setSearchText(e.currentTarget.value);
  }
  function clear() {
    setSearchText("");
  }

  if (error) {
    showToast(error.message, "error");
  }

  return (
    <div
      className={cn(
        {
          "visible opacity-100": displaySearch,
          "opacity-0 hidden": !displaySearch,
        },
        "fixed bg-black/50 top-0 left-0 z-[99999] transition duration-300 ease-in-out px-4 w-full h-screen"
      )}
    >
      <div
        className="w-full md:w-[730px] lg:w-[930px] mx-auto"
        ref={searchContainerRef}
      >
        <div className="mt-3.5 lg:mt-4 w-full">
          <div className="flex flex-col mx-auto mb-1.5 w-full ">
            <SearchBox
              onSubmit={handleSearch}
              onChange={handleAutoSearch}
              name="search"
              value={searchText}
              onClear={clear}
              ref={(input) => input && input.focus()}
            />
          </div>
          {searchText && (
            <div className="h-[500px] overflow-y-scroll bg-white rounded-md">
              {fetching ? (
                <div className="p-5 border-b border-gray-300 border-opacity-30 last:border-b-0 flex flex-col gap-2">
                  {Array.from({ length: 20 }).map((_, idx) => (
                    <SearchResultLoader
                      key={idx}
                      uniqueKey={`top-search-${idx}`}
                    />
                  ))}
                </div>
              ) : (
                data?.products?.data
                  ?.map((row) => sanitize(row))
                  ?.map((item, index) => (
                    <div
                      className=" p-5 border-b border-gray-150 relative last:border-b-0"
                      onClick={closeSearch}
                      key={"searchItem" + index}
                    >
                      <SearchProduct item={item} />
                    </div>
                  ))
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
